import { Navigate, useLocation } from 'react-router-dom';

const userRole = localStorage.getItem('role');

export const RequireAuth = ({ children, requiredRole }) => {
  const location = useLocation();
  if (userRole !== requiredRole) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  return children;
};

export const SubscriberRoute = ({ children, offerRequired }) => {
  const userOffer = localStorage.getItem('myoffer');
  const location = useLocation();

  if (userRole !== "admin" && userOffer !== offerRequired) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};


