import React, { useEffect, useState } from "react";
import axios from "axios";
import { DoneToast, ErrToast } from "../../globalElements/AllToasts";
import { toast, Toaster } from "react-hot-toast";
import Select from 'react-select';
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";

export default function AddSubscription({ userid }) {
  const localhost = process.env.REACT_APP_LOCALHOST;
  const token = localStorage.getItem('token');
  const [showModal, setShowModal] = useState(false);
  const [duree, setDuree] = useState("");
  const [offer, setOffer] = useState("");
  const [res, setRes] = useState([]);

  useEffect(()=>{
    axios.get(`${localhost}/checksubscription/${userid}`, { headers: { 'Authorization': `Bearer ${token}` } })
    .then((res) => {
      setRes(res.data);
    })
    .catch((err) => console.error('Error'));
  },[]);
  
  const checkout = (e) => {
    e.preventDefault();
    const data = { "duree": duree, "offer": offer }
    axios.post(`${localhost}/addsubscription/${userid}`, data, { headers: { 'Authorization': `Bearer ${token}` } })
      .then((res) => {
        toast(<DoneToast text="The action completed successfully" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        setTimeout(() => { window.location.reload() }, 2000);
      })
      .catch((err) => {
        toast(<ErrToast text="There is problem!" />,
          {
            style: { background: 'none', boxShadow: 'none' },
            duration: 2000,
            position: 'top-center',
          });
        console.error('Error');
      });
  }

  const dureeOptions = [
    { value: "threeMonths", label: "3 Months" },
    { value: "sixMonths", label: "6 Months" },
    { value: "oneYear", label: "1 Year" }
  ];

  const offerOptions = [
    { value: "forex", label: "Forex" },
    { value: "crypto", label: "Crypto" }
  ];

  return (
    <>
      <Toaster />
      {!res.is_subscribed?
      (<button onClick={() => setShowModal(true)} className="flex ml-auto items-center bg-blue-600 text-white hover:bg-blue-700 rounded-md px-3 py-2 text-center text-sm font-medium outline-none disabled:text-blue-300" >
        <PlusIcon className="text-white mr-1 h-5 w-5" />
        Subscribe
      </button>)
      :(<p>{res.time_left}</p>)}
      {showModal ? (
        <div className="bg-blue-600/30 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative animate-fade-up animate-duration-[1500ms] animate-ease-out w-auto my-6 mx-auto max-w-3xl">
            <div className="mx-auto max-w-md pt-20">
              <div className="rounded-xl bg-white text-gray-800 dark:text-white dark:bg-gray-900 shadow-lg">
                <div className="container p-3 mx-auto flex flex-wrap flex-col">

                  <form onSubmit={checkout}>
                    <div className="mx-auto p-5">
                      <button className="float-end text-gray-900 dark:text-white bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 hover:text-gray-900 rounded-lg p-1 inline-flex items-center" onClick={() => setShowModal(false)}>
                        <XMarkIcon className="h-5 w-5 text-gray-800 dark:text-white" />
                      </button>
                      <p className="block mb-4 text-lg font-medium text-gray-900 dark:text-white">Add Subscription</p>
                      <div className="max-w-sm mb-3">
                        <label htmlFor="duree" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Duree</label>
                        <Select id="duree" value={dureeOptions.find(option => option.value === duree)} className="rounded-lg text-gray-800 bg-white"
                          onChange={(option) => setDuree(option.value)} options={dureeOptions} isSearchable={false} />
                      </div>
                      <div className="max-w-sm mb-3">
                        <label htmlFor="offer" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Offer</label>
                        <Select id="offer" value={offerOptions.find(option => option.value === offer)} className="rounded-lg text-gray-800 bg-white"
                          onChange={(option) => setOffer(option.value)} options={offerOptions} isSearchable={false} />
                      </div>
                      <input type="submit" value="Submit" className="block cursor-pointer mt-2 py-2 px-3 mb-3 w-28 bg-blue-600 text-white float-end rounded-lg" />
                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ""}
    </>
  )
}